@import "@/assets/scss/style.scss";

.topBar {
    &__row--search {
        display: flex;
        @media (max-width: 575px) {
            flex-direction: column;
        }
    }
    &__col {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.c-language-picker {
    &__select {
        width: 95%;
    }
}

.switchWrapper {
    display: flex;
    flex-wrap: nowrap;

    span {
        margin-right: 4px;
    }

    &__text {
        margin-left: 10px;
    }
}

.inputElement {
    width: 90%;
    min-width: 50px;
}

.highlight {
    background: #fafafa;
    margin-bottom: 10px;
}
