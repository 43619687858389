@import "@/assets/scss/style.scss";

.c-translation {
    padding: 24px 24px;
    background-color: #fff;
}

.c-translation-row {
    // display: flex;
    // flex-wrap: nowrap;
    margin-bottom: 8px;
    width: 100%;
    &:last-child {
        margin-bottom: 0;
    }
}

.c-translation-field {
    width: 100%;
    &__before {
        word-break: normal;
        display: inline-flex;
        padding: 0 11px;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
    }
}

.editor::v-deep {
    .ProseMirror {
        min-height: 165px;
        height: 165px;
        overflow-y: auto;
    }
}
